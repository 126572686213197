import {fadeIn} from "../plugins/modals/fade-in";
import {i18Translate} from "./i18-init";

/**
 * Generates a modal window with an error
 * notification with default text
 * @param {string} description
 */
export const notificationError = (description = i18Translate('notification-error.default-error')) => {

    if (document.querySelector('#notification-error')) {
        return false
    }

    const body = document.querySelector('body')

    const template = `
        <div class="block-error" id="notification-error">
            <div class="block-error-group">
                <p id="description">${description}</p>
                <span class="btn-custom btn-color-blue" id="notification-error-close">${i18Translate('notification-error.clear')}</span>
            </div>
        </div>
    `

    //clearNotification()

    body.insertAdjacentHTML('beforeend', template)

    fadeIn({
        dialog: body.querySelector('#notification-error'),
        duration: 100
    })

    body.querySelectorAll('#notification-error-close').forEach(el => el.addEventListener('click', e => {
        e.target.closest('.block-error').remove()
    }))

}

const clearNotification = () => {
    const notifications = document.querySelectorAll('#notification-error')

    for (let i = 0; notifications.length > i; i++) {
        notifications[i].remove()
    }
}
